<template>
  <div class="csn-horizontal-list">
    <div class="csn-horizontal-list-header">
      <h3 v-if="title" class="section-title">{{ title }}</h3>
      <Route
        v-if="showAllRouteName"
        :to="{ name: showAllRouteName }"
        class="csn-horizontal-list-show-all"
      >
        {{ t('show_all') }}
      </Route>
    </div>
    <Loader v-if="isInitialLoading" />
    <div v-else-if="isListEmpty">{{ t('empty_list') }}</div>
    <div v-else>
      <div
        v-if="isProviderList"
        class="csn-providers"
        :class="{ 'csn-providers-five': isCasinoFive }"
      >
        <div :class="providerListClass" :ref="providerGameListRef">
          <button @click="handleLeftButtonClick" :class="leftButtonClass">
            <SlideArrow :isBack="true" />
          </button>
          <button @click="handleRightButtonClick" :class="rightButtonClass">
            <SlideArrow />
          </button>

          <slider :options="options" @slide="slide" :ref="ref">
            <slideritem
              v-for="{ logoUrl, id, name, slug } in list"
              :key="id"
              class="csn-slider-card"
            >
              <div :class="providerItemClass" :id="slug">
                <Route
                  :to="{ name: route.gameVendor, params: { slug } }"
                  class="csn-provider-list-item-wrapper"
                >
                  <img v-if="logoUrl" :src="logoUrl" :alt="slug" :id="slug" />
                  <span v-else>
                    {{ name }}
                  </span>
                </Route>
              </div>
            </slideritem>

            <div slot="loading"><Loader /></div>
          </slider>
        </div>
      </div>

      <div v-else :class="gameListClass">
        <button @click="handleLeftButtonClick" :class="leftButtonClass">
          <SlideArrow :isBack="true" />
        </button>
        <button @click="handleRightButtonClick" :class="rightButtonClass">
          <SlideArrow />
        </button>

        <slider :options="options" @slide="slide" :ref="ref">
          <slideritem
            v-for="game in list"
            :key="game.id"
            class="csn-slider-card"
          >
            <GameBoxWithHover :game="game" :isHigh="isVertical" />
          </slideritem>
          <div slot="loading"><Loader /></div>
        </slider>
      </div>
    </div>
  </div>
</template>
<script>
import {
  HORIZONTAL_LIST,
  Digit,
  EMPTY_STRING,
  ResponseState,
  EMPTY_OBJECT,
  ID,
  RouteName,
  CARUSEL_WRAPPER,
  CSN_HORIZONTAL_GAME_LIST,
  CSN_HORIZONTAL_GAME_LIST_HIGH,
  SLIDER,
  SLIDE_PRE,
  SLIDE_NEXT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
  CASINO_FIVE,
} from '@/constants'
import { slider, slideritem } from 'vue-concise-slider'
import {
  callFn,
  generateUUID,
  isNil,
  isEmpty,
  isEmptyOrNil,
  requestTimeout,
  cancelTimeout,
  values,
  indexBy,
  pipe,
  remove,
} from '@/helpers'

const RIGHT = 'right'
const PROVIDER_GAME_LIST_REF = 'providerGameListRef'
const providerListClass = 'csn-horizontal-game-list csn-provider-list'
const providerItemClass = 'box  casino-game-list-box csn-provider-list-item'

export default {
  name: HORIZONTAL_LIST,
  components: {
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
    slider,
    slideritem,
    GameBoxWithHover: () => import('@/components/GameBoxWithHover'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  data: () => ({
    list: {},
    total: Digit.NOUGHT,
    page: Digit.NOUGHT,
    firstVisibleItem: Digit.NOUGHT,
    showsLeftButton: false,
    showsRightButton: false,
    isSlideDisabled: false,
    isLoading: false,
    isVertical: false,
    resetRequest: null,
  }),
  props: {
    title: String,
    itemsPerScreen: Number,

    isHigh: Boolean,
    isProviderList: Boolean,
    showAllRouteName: {
      type: String,
      default: EMPTY_STRING,
    },
    getList: Function,
  },
  computed: {
    ref: () => `${SLIDER}${generateUUID()}`,
    providerGameListRef: () => PROVIDER_GAME_LIST_REF,
    providerListClass: () => providerListClass,
    providerItemClass: () => providerItemClass,

    options() {
      return {
        currentPage: Digit.NOUGHT,
        pagination: false,
        slidesToScroll: this.itemsPerScreen,
        loopedSlides: this.itemsPerScreen,
      }
    },
    listLength() {
      return Object.values(this.list).length
    },
    leftButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
        this.showsLeftButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
    rightButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
        this.showsRightButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
    t() {
      return this.$createComponentTranslator(HORIZONTAL_LIST)
    },
    isListEmpty() {
      return isEmpty(this.list)
    },
    gameListClass() {
      return [
        CARUSEL_WRAPPER,
        CSN_HORIZONTAL_GAME_LIST,
        this.isVertical ? CSN_HORIZONTAL_GAME_LIST_HIGH : EMPTY_STRING,
      ]
    },
    hasMore() {
      return this.page * this.itemsPerScreen < this.total
    },
    isInitialLoading() {
      return this.isLoading && this.isListEmpty
    },
    route: () => ({
      gameVendor: RouteName.GAME_VENDOR,
    }),
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
  },
  watch: {
    itemsPerScreen() {
      this.reset()
    },
    list: {
      immediate: true,
      handler(list) {
        const nextFirstItem = this.firstVisibleItem + this.itemPerScreen
        const isFirstItemLoaded = !isEmpty(list[this.firstVisibleItem])
        const existsNextFirstItem = !isNil(list[nextFirstItem])
        const canShowNextSlideGroup = isFirstItemLoaded && existsNextFirstItem

        this.showsRightButton !== canShowNextSlideGroup &&
          (this.showsRightButton = canShowNextSlideGroup)
      },
    },
  },
  methods: {
    async loadFirstListFragment() {
      await callFn(this.updateList)
      this.fillListWithEmptyItems()

      const showsRightButton = this.list.length > this.itemsPerScreen
      this.showsRightButton !== showsRightButton &&
        (this.showsRightButton = showsRightButton)
    },
    reset() {
      this.resetRequest = requestTimeout(() => {
        this.showsLeftButton = false
        this.showsRightButton = false
        this.isSlideDisabled = false
        this.list = {}
        this.total = Digit.NOUGHT
        this.page = Digit.NOUGHT
        this.isLoading = false
        this.firstVisibleItem = Digit.NOUGHT

        this.loadFirstListFragment()
        cancelTimeout(this.resetRequest)
      }, Digit.FIVE_HUNDRED)
    },
    handleLeftButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_PRE)
    },
    handleRightButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_NEXT)
    },
    async updateList() {
      this.isLoading = true

      const nextPage = this.page + Digit.ONE
      const { data, state } = await callFn(this.getList, {
        page: nextPage,
        limit: this.itemsPerScreen,
      })

      if (state === ResponseState.OK) {
        const newFragment = {}
        const firstEmptyItemId = this.page * this.itemsPerScreen
        const listDictionaryById = pipe(
          values,
          indexBy(ID),
          remove([undefined]),
        )(this.list)

        data?.items.forEach((el, i) => {
          if (listDictionaryById[el.id]) {
            return
          }

          const itemId = firstEmptyItemId + i

          newFragment[itemId] = el
        })

        this.list = { ...this.list, ...newFragment }
        this.total = data?.total
        this.page = nextPage
        this.isVertical = data?.vertical || this.isHigh
      }

      this.isLoading = false
    },
    fillListWithEmptyItems() {
      if (this.hasMore) {
        const newFragment = {}
        const defaultFilledListLength = this.listLength + this.itemsPerScreen
        const remainder = this.total - this.listLength
        const emptyItemNumber =
          this.total > defaultFilledListLength ? this.itemsPerScreen : remainder
        const emptyItemList = new Array(emptyItemNumber).fill(EMPTY_OBJECT)

        emptyItemList.forEach((item, index) => {
          const itemId = this.listLength + index

          newFragment[itemId] = item
        })

        this.list = { ...this.list, ...newFragment }
      }
    },
    async slide({ currentPage: firstVisibleItem, direction }) {
      if (this.isSlideDisabled) {
        return
      }

      this.firstVisibleItem = firstVisibleItem
      this.isSlideDisabled = true
      const slidesRight = direction === RIGHT

      if (slidesRight && this.hasMore) {
        const defaultLoadedListLength = this.page * this.itemsPerScreen
        const appliesDefaultLength = this.total > defaultLoadedListLength
        const loadedListLength = appliesDefaultLength
          ? defaultLoadedListLength
          : this.total
        const defaultNextListLength = loadedListLength + this.itemsPerScreen
        const appliesNextDefaultLength = this.total > defaultNextListLength
        const nextLoadedFragmentLength = appliesNextDefaultLength
          ? this.itemsPerScreen
          : this.total - loadedListLength
        const rendersNewItems =
          firstVisibleItem + nextLoadedFragmentLength > loadedListLength
        const mustLoadNextFragment = isEmptyOrNil(this.list[loadedListLength])

        if (rendersNewItems && mustLoadNextFragment) {
          await this.fillListWithEmptyItems()
          await this.updateList()
        }
      }

      const showsLeftButton = firstVisibleItem >= this.itemsPerScreen
      const showsRightButton =
        firstVisibleItem + this.itemsPerScreen < this.listLength

      this.showsLeftButton !== showsLeftButton &&
        (this.showsLeftButton = showsLeftButton)

      this.showsRightButton !== showsRightButton &&
        (this.showsRightButton = showsRightButton)

      this.isSlideDisabled = false
    },
  },
  mounted() {
    this.loadFirstListFragment()
  },
}
</script>

<style></style>
